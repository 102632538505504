.workflow-entry-stepper-container {
    .step-tabs {
        >.MuiBox-root {
            >div {
                min-height: 52px;
            }
        }

        .MuiTabPanel-root {
            padding: 0px;
        }

        .MuiTabs-scroller {


            .MuiTabs-indicator {
                display: none;
            }

            >div {
                //justify-content: center;

                button,
                a {


                    //background-color: red;
                    justify-content: flex-start;
                    position: relative;

                    &::before {
                        content: "";
                        height: 3px;
                        position: absolute;
                        left: 0;
                        right: 83px;
                        top: 32px;
                        background-color: #fff;
                        z-index: 0;
                        box-shadow: 0 3px 0 0 #ccc;
                    }

                    &::after {
                        content: "";
                        height: 3px;
                        position: absolute;
                        left: 83px;
                        right: 0;
                        top: 32px;
                        background-color: #fff;
                        z-index: 0;
                        box-shadow: 0 3px 0 0 #ccc;
                    }

                    .step {
                        //background: blue;
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        align-items: center;
                        width: 100px;

                        position: relative;



                        >div {
                            width: 30px;
                            height: 30px;
                            //background: #ccc;
                            border: 5px solid white;
                            border-radius: 99px;
                            margin-bottom: 10px;
                            box-shadow: 0 3px 0 0 #ccc, inset 0 3px 0 0 #ccc;
                            position: relative;
                            z-index: 1;
                            // &::before {
                            //     content: "";
                            //     width: 30px;
                            //     height: 30px;
                            //     position: absolute;
                            //     left: 0;
                            //     right: 0;
                            //     border-radius: 99px;
                            //     background-color: var(--color-light);
                            //     z-index: 0;
                            // }

                            >span {
                                background-color: #fff;
                                display: block;
                                border-radius: 99px;
                                width: 30px;
                                height: 30px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #fff;
                                border: 3px solid var(--color-primary);
                                box-sizing: border-box;

                                svg {
                                    font-size: 20px;
                                }
                            }
                        }

                        >span {
                            >span {
                                display: block;
                                font-size: .9em;
                                text-align: center;
                                //color: #829fb0;
                                color: var(--color-text-tint);
                                text-transform: none;
                                font-weight: 300;
                            }
                        }
                    }
                }

                a:first-child,
                button:first-child {
                    &::before {
                        left: 50%;
                    }
                }

                a:last-child,
                button:last-child {
                    &::after {
                        right: 50%;
                    }
                }
            }

            .Mui-selected {
                .step {
                    >div {
                        >span {
                            background-color: var(--color-primary) !important;

                            >svg {
                                color: #fff !important;
                            }
                        }
                    }
                }
            }

            .MuiTabs-scrollButtons {
                svg {}
            }
        }
    }
}

@media(max-width: 600px) {
    .workflow-data-stepper-cell-container {}

}