.dynamic-list-item-container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 0 #ddd);
    // border-color: var(--color-medium);
    // border-width: 2px;
    // border-style: solid;

    >.dynamic-list-item-cells {
        display: flex;
        justify-content: space-between;
        flex: 1;


        >.left-cells {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        >.right-cells {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

    }

    >.dynamic-list-item-actions {

        display: flex;
        justify-content: flex-end;
        align-items: center;

        >svg {
            cursor: move;
        }

        >button {
            margin: 0 3px;
        }
    }
}