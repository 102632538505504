.status-cell-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    //padding: 5px 9px 5px 7px;
    padding: 5px 8px;
    border-radius: 99px;
    color: #fff;
    text-align: center;

    >svg {
        margin-right: 3px;
        font-size: 1.2em;
    }

    >span {
        position: relative;
        top: -1px;
        font-weight: 400;
        font-size: .9em;
    }


}

.status-popover-items {
    display: flex;
    flex-direction: column;

    >button {

        >svg {
            margin-right: 5px;
            font-size: 1.1em;
            position: relative;
            top: 2px;
        }
    }
}