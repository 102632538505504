.video-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding-top: 20px;

    >label {
        display: inline-block;
        padding: 0 5px;
        //background-color: #fff;
        position: absolute;
        left: 10px;
        top: 5px;
        font-weight: 500;
        color: var(--color-text);
        z-index: 1;
        font-size: 1em;
    }

    >.content {
        position: relative;

        >.editable-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
        }

        >.readonly-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            padding: 10px;

            .no-value {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #fff;
                padding: 10px;
                border-radius: 10px;
                width: 100px;
                height: 100px;
                // border-color: var(--color-medium);
                // border-width: 1px;
                // border-style: solid;

                >svg {
                    color: var(--color-medium);
                }

                >span {
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    color: var(--color-text);
                    font-size: .9em;
                    margin-top: 10px;
                }

            }
        }
    }

    >.btns {
        position: absolute;
        right: 10px;
        top: 5px;


    }

    .video-preview-wrapper {
        width: 220px;
        height: 220px;
        position: relative;
        //background-color: red;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 10px;
        background-color: #fff;

        video {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            margin: auto;
        }

        >button {
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: var(--color-primary);

            >svg {
                color: var(--color-text-shade);
            }
        }
    }

    .spinner-wrapper {
        height: 80px;

        >* {
            position: relative;
            top: -20px;
        }
    }
}