.item-fields-container {
    display: flex;
    justify-content: center;
    align-items: center;

    >.field-wrapper {
        margin: 3px;
        padding: 3px 6px 3px 0px;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: var(--color-medium);
    }

    >.field-wrapper:last-child {
        border-right-width: 0px;
    }

    .item-field-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        width: 100%;

        >span {
            font-size: .8em;
            display: block;
            text-align: center;
            line-height: 1;
        }

    }

}