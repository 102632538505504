.step-list-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 10px 10px 10px 20px;
    box-sizing: border-box;


}

.step-item {
    filter: drop-shadow(0px 1px 0 #ccc);
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    >span {
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 99px;
        top: 50%;
        margin-top: -20px;
        left: -10px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        >strong {
            color: var(--color-primary);
            font-weight: 900;
        }
    }

    >div:nth-of-type(1) {
        padding-left: 30px;
    }

    >div:nth-of-type(2) {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .drag-handle-wrapper {
            width: 40px;
            height: 40px;
            cursor: move;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // //position: relative;
    // >div:nth-of-type(1) {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;

    //     >div {
    //         display: flex;
    //         align-items: center;
    //     }

    //     >div:nth-of-type(1) {
    //         span {
    //             display: block;
    //             margin-left: 10px;
    //             font-weight: 500;
    //         }
    //     }

    //     >div:nth-of-type(2) {
    //         cursor: move;
    //     }
    // }

    // >div:nth-of-type(2) {}

}