.due-date-tabs-container {
    background-color: transparent !important;

    .MuiTabs-scroller {
        background-color: transparent;

        >div {
            //justify-content: flex-start;
        }

        .MuiTabs-indicator {
            display: none;
        }

        a {
            padding: 5px !important;
            color: var(--color-text);
            cursor: pointer;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 70px;
                position: relative;
                background-color: #fff;
                border-radius: 10px;
                padding: 5px;
                box-sizing: border-box;

                >div {
                    line-height: 1;
                    position: relative;
                    z-index: 1;
                }

                // .today {
                //     font-weight: 900 !important;
                //     font-size: 1.5em !important;
                //     position: relative;
                //     top: 5px;
                // }
                strong {
                    font-weight: 900 !important;
                    font-size: 1.5em !important;
                    // position: relative;
                    // top: 5px;
                }

                >div:nth-of-type(1) {
                    font-weight: 300;
                    //color: #999;
                    font-size: 12px;
                    margin-bottom: 2px;
                    position: relative;
                    left: -2px;
                }

                >div:nth-of-type(2) {
                    font-weight: 900;
                    //color: var(--bib-color);
                    font-size: 19px;
                    margin-top: 0px;
                    margin-bottom: 2px;
                    position: relative;
                    left: -2px;
                }

                >div:nth-of-type(3) {
                    font-weight: 900;
                    //color: var(--bib-color);
                    font-size: 14px;
                    position: relative;
                    left: -2px;
                }


                >span.pending-count {
                    font-weight: 900;
                    border-radius: 99px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #eeeeee;
                    position: absolute;
                    font-size: 12px;
                    width: 33px;
                    height: 33px;

                    top: -9px;
                    right: -9px;
                    z-index: 0;

                    &::before {
                        content: '';
                        width: 23px;
                        height: 23px;

                        background-color: #fff;
                        border-radius: 100%;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        box-shadow: 0 1.5px 0 0 #ccc;
                    }

                    >span {
                        color: var(--color-primary);
                        z-index: 1;
                    }
                }

                >span.bg {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 70px;
                    height: 70px;
                    background-color: #fff;
                    border-radius: 10px;
                    z-index: 0;
                    filter: drop-shadow(0 1.5px 0 #ccc);
                }

                >svg {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 0;
                    filter: drop-shadow(0px 1.5px 0 #ccc);

                    path {
                        fill: #fff;
                    }
                }

            }


        }

        a {
            .selected {
                background-color: var(--color-primary);
                color: #fff;

                >svg {
                    path {
                        fill: var(--color-primary) !important;
                    }
                }

                >span.bg {
                    background-color: var(--color-primary);
                }

                >span.pending-count {
                    &::before {
                        background-color: var(--color-primary);
                    }

                    >span {
                        color: #fff;
                    }
                }
            }
        }

        .Mui-selected {
            color: var(--color-text);
        }
    }

}