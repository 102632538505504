.workflow-stepper-cell-container {
    display: flex;
    justify-content: flex-end;
    align-items: start;
    flex-wrap: wrap;

    >.step-group:first-child {
        &::after {
            content: none;
        }

        >.step:first-child {
            &::before {
                left: 50%;
            }
        }
    }

    >.step-group:last-child {
        &::before {
            content: none;
        }

        >.step:last-child {
            &::before {
                right: 50%;
            }
        }
    }

    >.step-group {
        //background-color: red;
        display: flex;
        justify-content: flex-end;
        align-items: start;
        position: relative;
        margin: 5px 0;

        &::before {
            border-top: 2.5px dashed var(--color-primary);
            content: '';
            display: block;
            height: 0;
            position: absolute;
            right: 0;
            top: 56px;
            right: -17px;
            //transform: translate(0, -6px);
            width: 15px;
            //z-index: 99;
        }

        &::after {
            border-top: 2.5px dashed var(--color-primary);
            content: '';
            display: block;
            height: 0;
            position: absolute;
            right: 0;
            top: 56px;
            left: -17px;
            //transform: translate(0, -6px);
            width: 15px;
            //z-index: 99;
        }

        >.step {
            //background-color: blue;
            //margin: 4px;
            height: 70px;
            position: relative;
            box-sizing: border-box;
            width: 90px;

            &::before {
                content: "";
                height: 2.5px;
                position: absolute;
                left: 0;
                right: 0;
                //top: 36px;
                bottom: 12px;
                background-color: var(--color-primary);
            }

            >.step-name {
                //background-color: violet;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                >h3 {
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 400;

                    /* Estilo para limitar el alto a 3 líneas y agregar puntos suspensivos */
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

            }


            >span {
                align-items: center;
                //background-color: var(--color-primary);
                background-color: var(--color-light);
                border: 3px solid var(--color-primary);
                border-radius: 100%;
                display: flex;
                height: 25px;
                justify-content: center;
                left: 50%;
                position: absolute;
                // top: 25px;
                // 
                bottom: 0;
                transform: translate(-50%, 0);
                width: 25px;
                box-sizing: border-box;
                z-index: 100;
                //color: #fff;
                color: var(--color-primary);
                font-weight: 900;
                font-size: 16px;
                line-height: 14px;
            }

            >div {}

        }
    }
}

// .workflow-stepper-cell-container {
//     display: inline-flex;

//     * {
//         box-sizing: border-box;
//     }

//     .step {
//         position: relative;
//         width: 60px;
//         min-height: 55px;
//         padding-left: 3px;
//         padding-right: 3px;
//         box-sizing: border-box;

//         &::before {
//             content: "";
//             height: 2px;
//             position: absolute;
//             left: 0;
//             right: 0;
//             top: 36px;
//             background-color: var(--color-primary);
//         }

//         h3 {
//             color: var(--color-primary);
//             display: block;
//             font-size: 13px;
//             height: 15px;
//             // line-height: 20px;
//             margin: 0;
//             text-align: center;
//             text-overflow: ellipsis;
//             padding: 0 2px;

//             white-space: nowrap;
//             box-sizing: border-box;
//         }

//         >span {
//             align-items: center;
//             background-color: #fff;
//             border: 3px solid var(--color-primary);
//             border-radius: 100%;
//             display: flex;
//             height: 25px;
//             justify-content: center;
//             left: 50%;
//             position: absolute;
//             top: 25px;
//             transform: translate(-50%, 0);
//             width: 25px;
//             box-sizing: border-box;
//             z-index: 100;

//             svg {
//                 color: #fff;
//                 font-size: 20px;
//             }

//             span {
//                 position: relative;
//                 top: 0px;
//                 //left: 4px;
//                 display: block;
//                 width: 25px;
//                 color: #fff;
//                 text-align: center;
//                 font-size: 16px;
//                 font-weight: 900;
//                 box-sizing: border-box;
//             }
//         }

//         >div {
//             margin-top: 40px;
//             text-align: center;
//             box-sizing: border-box;

//             // .step-state-display-name {
//             //     color: var(--color-primary);
//             //     display: block;
//             //     font-size: 12px;
//             //     font-weight: 300;
//             // }

//             // .step-updated-at {
//             //     color: #999;
//             //     display: block;
//             //     font-weight: 300;
//             //     font-size: 11px;
//             // }

//             // .step-total {
//             //     margin: 4px 0px 0px 0px;

//             //     >span {
//             //         display: block;
//             //     }

//             //     >span:nth-of-type(1) {
//             //         color: #666;
//             //         font-weight: 300;
//             //         font-size: 12px;
//             //     }

//             //     >span:nth-of-type(2) {
//             //         color: #333;
//             //         font-weight: 900;
//             //         font-size: 16px;
//             //     }
//             // }

//             // .step-description {
//             //     margin: 0px;
//             //     font-weight: 300;
//             //     font-size: 12px;
//             //     color: #888888;
//             //     margin-top: -2px;
//             // }
//         }
//     }

//     >div:first-child {
//         &::before {
//             left: 50%;
//         }
//     }

//     >div:last-child {
//         &::before {
//             right: 50%;
//         }
//     }
// }

// @media(max-width: 600px) {
//     .workflow-stepper-cell-container {
//         margin-top: 25px;
//         margin-left: 0 !important;
//         flex-wrap: wrap;

//         .step {
//             flex: 1 0 50%;

//             &:nth-child(n + 3) {
//                 margin-top: 10px;
//             }

//             &:nth-child(2n)::after {
//                 border-top: 7px dotted var(--color-light);
//                 content: '';
//                 display: block;
//                 height: 0;
//                 position: absolute;
//                 right: 0;
//                 top: 40px;
//                 transform: translate(0, -6px);
//                 width: 45px;
//                 z-index: 99;

//             }

//             &:nth-child(2n + 1)::after {
//                 border-top: 7px dotted var(--color-light);
//                 content: '';
//                 display: block;
//                 height: 0;
//                 position: absolute;
//                 left: 0;
//                 top: 50%;
//                 transform: translate(0, 6px);
//                 width: 45px;
//                 z-index: 99;
//             }
//         }
//     }

// }