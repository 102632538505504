.ring-binder-container {

    cursor: pointer;
    color: var(--color-text);
    position: relative;
    display: inline-block;
    text-align: center;





    .ring-binder-label {
        position: absolute;
        color: var(--color-text);
        /* Adjust as needed */
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
    }

    .ring-binder-label.vertical {
        width: 60px;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        >div {
            font-weight: 400;
            line-height: 1.2;
            font-size: .9em;
        }
    }

    .ring-binder-label.horizontal {
        width: 120px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: flex-start;

        >div {
            font-weight: 400;
            line-height: 1.2;
            font-size: .9em;
        }
    }
}