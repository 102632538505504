.money-field-container {
    width: 100%;
    border-color: var(--color-medium);
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    box-sizing: border-box;
    //background-color: #fff;
    //background: var(--color-light);
    position: relative;


    >label {
        position: absolute;
        top: -13px;
        left: 10px;
        display: inline-block;
        padding: 0 5px;
        background: #fff;
        font-weight: 600;
        color: var(--color-text);

    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;


        >div:nth-of-type(1) {
            width: 100%;

            span {
                height: 40px;
                line-height: 34px;
                color: var(--color-text);
                display: block;
                font-weight: 400;
                padding-top: 4px;
                box-sizing: border-box;
            }

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;

                >span {
                    padding: 4px;
                    font-weight: 900;
                    line-height: 30px;
                    color: var(--color-text-tint);

                }

                input {

                    border-radius: 99px;
                    width: 100%;
                    height: 30px;
                    line-height: 25px;
                    border: none;
                    font-size: .9em;
                    padding: 5px;
                    color: var(--color-text);
                    font-weight: 500;
                    outline: none;
                    text-align: right;
                    //background: var(--color-light);
                    //background-color: #fff;
                    //background-color: #fff !important;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    box-shadow: 0 0 0 30px #fff inset !important;
                }
            }

            >.readonly-content {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: nowrap;

                >.value-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-wrap: nowrap;

                    >span {
                        display: block;
                        margin-right: 5px;
                        font-weight: 900;
                    }
                }


                // >span {
                //     padding: 4px;
                //     font-weight: 600;
                //     line-height: 30px;
                // }
            }

        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;

                cursor: pointer;
            }
        }

        /*  svg {
            color: var(--color-text-tint);
        } */
    }

}