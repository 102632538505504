.billing-field-container {
    position: relative;
    margin: 0 !important;
    padding-top: 0px;
    filter: none;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 15px;

        >label {
            display: inline-block;
            font-weight: 500;
            color: var(--color-text);
            font-size: 1em;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding-bottom: 5px;
    }

    .input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        background-color: #fff;
        border-radius: 10px;

        >span {
            padding: 5px !important;
            font-weight: 700;
            position: relative;
            top: -2px;

        }

        >input {


            width: 100%;
            height: 30px;
            line-height: 25px;
            border: none;
            font-size: .9em;
            padding: 5px;
            color: var(--color-text);
            font-weight: 500;
            outline: none;
            box-sizing: border-box;
            text-align: right;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0 30px #fff inset !important;
        }
    }

    .amount-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;

        >span {
            display: block;
            margin-right: 5px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .billing-field-container {
        .items-wrapper {


            table {
                thead {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                tr {
                    // background: white !important;
                    // box-shadow: 0 .5px 0 0 #ccc;
                    display: block;
                    margin-bottom: 25px;
                    padding: 10px;
                    position: relative;
                    box-sizing: border-box;
                    background-color: var(--color-light);
                    border-radius: 10px;

                    td {
                        align-items: center;
                        background-color: transparent !important;
                        border-bottom: none;
                        display: flex;
                        justify-content: space-between;
                        text-align: right;
                        margin: 3px 0;

                        &:before {
                            content: attr(data-column-header);
                            font-weight: 600;
                            text-transform: uppercase;
                            text-align: start;
                        }

                        >span {
                            border: none !important;
                            margin-bottom: 0 !important;
                            padding: 0 !important;
                            text-align: right;
                        }

                        // &:last-child {
                        //     display: none;
                        // }
                        &:last-child {
                            //position: relative;
                            display: flex;
                            justify-content: center;
                            height: 5px;

                            >button {
                                position: relative;
                                bottom: -15px;
                                background-color: var(--color-light);
                            }
                        }
                    }
                }
            }
        }

    }
}