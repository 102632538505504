.unpaid-documents-picker-container {
    >a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-light);
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        >div:nth-of-type(1) {}

        >div:nth-of-type(2) {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            >div:nth-of-type(1) {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }

    .blocked {
        opacity: .5;
    }
}