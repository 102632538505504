.field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;

    >.field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;
        top: 0;
        left: 0;
        z-index: 1;

        >label {
            display: inline-block;
            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }
    }

    >.field-body {
        position: relative;
        padding-top: 25px;
    }
}