.spinner {
    display: inline-block;
    height: 40px;
    width: 40px;
    border: 3px solid var(--color-primary);
    // border-width: 3px;
    // border-style: solid;
    border-radius: 50%;
    border-top-color: var(--color-light);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

.spinner.sm {
    height: 20px;
    width: 20px;
}

.spinner.md {
    height: 40px;
    width: 40px;
}

.spinner.lg {
    height: 60px;
    width: 60px;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}