.tags-modal-container {
    //position: absolute;

    .tag-visible-name {
        line-height: 1;
        font-weight: 600;
    }

    .fab {
        position: fixed;
        bottom: 20px;
        right: 20px
    }
}