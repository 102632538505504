.my-snaps-page-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    // height: 100%;
    // width: 100%;
    background-color: #ddd;

    .page-body {
        width: 100%;
        height: 100%;
        background-color: red;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        >.sidebar {
            width: 100%;
            max-width: 200px;
            height: 100%;
            box-sizing: border-box;
            transition: transform 0.3s ease-in-out;
            background-color: green;

            >a {
                display: none;
                width: 40px;
                height: 40px;
                background-color: var(--color-primary);
                color: #fff;

                justify-content: center;
                align-items: center;
                border-radius: 0 10px 10px 0;
                cursor: pointer;
            }
        }

        >.main-content {
            width: 100%;
            position: relative;
            height: 100%;

            >.query-wrapper {
                width: 100%;
                padding-left: 30px;
                padding-right: 30px;
                background-color: blue;
                box-sizing: border-box;
                position: absolute;
                bottom: 0px;

                >.query-container {

                    width: 100%;
                    box-sizing: border-box;
                    background-color: #fff;
                    padding: 5px 5px 35px 5px;
                    border-radius: 10px;
                    margin-bottom: 30px;
                    filter: drop-shadow(0px 2px 0 #ddd);

                    textarea {
                        width: 100% !important;
                        font-size: 16px;
                        border: none;
                        background-image: none;
                        font-family: inherit;
                        background-color: #fff !important;
                        display: block;
                        font-weight: 300;

                        padding: 10px;
                        height: 100px;
                        line-height: 1.2;
                        box-sizing: border-box;
                    }

                    >div {
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        bottom: -15px;
                        z-index: -1;



                        >div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #fff;
                            border-radius: 99px;
                            margin: 0 5px;
                            box-sizing: border-box;

                            >button {
                                color: #fff;
                            }
                        }

                        >div:nth-of-type(1) {
                            width: 50px;
                            height: 50px;

                            >button {
                                background-color: var(--color-primary);
                            }

                            >button.disabled {
                                opacity: .7;
                            }
                        }

                        >div:nth-of-type(2) {
                            width: 40px;
                            height: 40px;

                            >button {
                                background-color: var(--color-dark);
                            }

                            >button.disabled {
                                background-color: var(--color-medium);
                            }
                        }

                    }
                }

            }
        }

    }


}

@media screen and (max-width: 600px) {
    .my-snaps-page-container {


        .page-body {

            .sidebar {
                z-index: 99;
                width: 85%;
                max-width: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: var(--color-light);

                >a {
                    display: flex;
                    position: absolute;
                    top: 10px;
                    right: -40px;
                    z-index: 11;
                }
            }

            .sidebar.visible {
                transform: translateX(0);
            }

            .sidebar.hidden {
                transform: translateX(-100%);
            }
        }
    }
}