.configuration-builder-modal-container {
    position: relative;

    .header-container {
        position: relative;
        z-index: 1;
    }

    .modal-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        height: 100%;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        //width: 100%;
        padding-top: 60px;

        .configuration-item-selector-wrapper {
            width: 100%;
            max-width: 300px;
            height: 100%;
            box-sizing: border-box;
            transition: transform 0.3s ease-in-out;


            >a {
                display: none;
                width: 40px;
                height: 40px;
                background-color: var(--color-primary);
                color: #fff;

                justify-content: center;
                align-items: center;
                border-radius: 0 10px 10px 0;
                cursor: pointer;
            }

            >div {
                width: 100%;
                height: 100%;
                overflow-y: auto;
            }
        }

        .selected-configuration-items-wrapper {
            height: 100%;
            width: 100%;
            //max-width: 600px;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            justify-content: center;
        }

        // .configuration-item-editor-wrapper {
        //     width: 400px;
        // }
        .preview-fab:hover {
            background-color: var(--color-medium);
        }
    }
}

@media screen and (max-width: 600px) {
    .configuration-builder-modal-container {
        .modal-body {
            .configuration-item-selector-wrapper {
                z-index: 99;
                width: 85%;
                max-width: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: var(--color-light);

                >a {
                    display: flex;
                    position: absolute;
                    top: 70px;
                    right: -40px;
                    z-index: 11;
                }
            }

            .configuration-item-selector-wrapper.visible {
                transform: translateX(0);
            }

            .configuration-item-selector-wrapper.hidden {
                transform: translateX(-100%);
            }

            .selected-configuration-items-wrapper {
                position: absolute;
                left: 0;
                right: 0;
            }
        }
    }
}