.mini-filled-shape-chart-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;

        >div:nth-of-type(1) {
            display: flex;
            flex-direction: column;
            align-items: center;
            //justify-content: center;
            //height: 70px;
            //padding: 5px;
            position: relative;
            margin: 5px;


            >div:nth-of-type(1) {

                // position: absolute;
                // top: 0;
                // top: 0;
                position: relative;

                // svg {
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     right: 0;
                //     bottom: 0;
                //     // width: 100%;
                //     // height: 100%;
                //     z-index: 99;
                // }
            }

            >div:nth-of-type(2) {
                position: absolute;
                top: 1px;
                left: 1px;
                // right: 0;
                // bottom: 0;
                background: var(--color-medium);
                z-index: -1;

            }

            >div:nth-of-type(3) {
                position: absolute;

                left: 1px;
                //right: 0.5px;
                bottom: 1px;
                // height: 20%;
                // background: #ccc;
                z-index: -1;
                animation: animHeight 1s ease-in-out;

            }
        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            top: -2px;

            >div {
                font-weight: 900;
                font-size: 1.2em;
                line-height: 1;
            }

            >span {
                font-weight: 300;
                color: var(--color-text-shade);
                font-size: .8em;
                text-align: center;
            }
        }

        .label {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            height: 17px;
        }
    }
}

@keyframes animHeight {
    from {
        height: 0px;
    }
}