.images-field-container {


    >.field-body {


        >.editable-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
        }

        >.readonly-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;

            .no-value {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #fff;
                padding: 10px;
                border-radius: 10px;
                width: 100px;
                height: 100px;

                >svg {
                    color: var(--color-medium);
                }

                >span {
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    color: var(--color-text);
                    font-size: .9em;
                    margin-top: 10px;
                }
            }
        }
    }

    .img-preview-wrapper {
        width: 120px;
        height: 120px;
        position: relative;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 10px;
        background-color: #fff;
        margin: 5px;

        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            margin: auto;
        }

        >button {
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: var(--color-primary);

            >svg {
                color: var(--color-text-shade);
            }
        }
    }

    .spinner-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        margin-top: 0px;
        margin-left: 5px;
        margin-right: 5px;
    }
}