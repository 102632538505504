.shelf-container {


    .binders {
        position: relative;
        display: flex;
        align-items: center;
        //margin-bottom: 20px;
        justify-content: center;
        z-index: 1;

        box-sizing: border-box;

        >* {
            position: relative;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .shelf {
        position: relative;
        top: -40px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 75px;

        >.shelf-start {
            height: 75px;
        }

        >.shelf-middle {
            position: relative;
            flex: 1;

            &::before {
                content: "";
                display: block;
                height: 51px;
                width: 100%;
                background-color: #68818e;
            }

            &::after {
                content: "";
                display: block;
                height: 24px;
                width: 100%;
                background-color: #4d6472;
            }
        }

        >.shelf-end {
            height: 75px;
        }
    }

    .shelf-2d {
        background-color: #4d6472;
        height: 30px;
        width: 100%;
        position: relative;
        top: -5px;
        z-index: 1;
    }
}

@media screen and (max-width: 460px) {
    .shelf-container {
        .binders {
            >* {
                margin-left: 2px;
                margin-right: 2px;
            }
        }
    }
}