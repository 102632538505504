.simple-indicator-table-container {
    padding: 0 20px;

    >table {
        width: 100%;

        width: 100%;
        border-collapse: collapse;

        th {
            background-color: var(--color-light); // Color de fondo oscuro para la cabecera
            //color: white; // Texto blanco para la cabecera
            color: var(--color-text-shade);
            padding: 10px; // Espaciado interno para las celdas
            border: 2px solid #fff;
            text-align: center;
        }

        td {
            padding: 10px; // Espaciado interno para las celdas
            text-align: center; // Alineación central del texto
        }

        tr:nth-child(even) {
            background-color: #f2f2f2; // Gris claro para filas pares
        }

        tr:nth-child(odd) {
            background-color: white; // Blanco para filas impares
        }

        //border-spacing: 0 10px;

        .variation-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: .9em;
        }

        .amount-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

        }
    }

}