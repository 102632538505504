.readonly-billing-item-container {
    background-color: var(--color-light);

    td {

        padding: 3px;

        >span {
            padding: 5px;
            //border-right: 1px solid #fff;
            display: block;
        }

        &:first-child {
            border-radius: 5px 0px 0px 5px;
        }

        &:last-child {
            border-radius: 0px 5px 5px 0px;

            >span {
                border: none;
            }
        }
    }
}