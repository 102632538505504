.image-cropper-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding-top: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 20px;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            //z-index: 1;
            font-size: 1em;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }


    .add-image-btn {
        display: inline-block;
        position: relative;
        border-radius: 10px;
        box-sizing: border-box;
        //filter: drop-shadow(0 2px 0 #ccc);
        width: 120px;
        height: 120px;
        padding: 5px;
        cursor: pointer;
        overflow: hidden;
        background-color: var(--color-light);


        >input {
            position: absolute;
            top: -99px;
        }

        >div {
            display: flex;
            align-items: center;
            width: 110px;
            height: 110px;
            border-radius: 10px;
            //border: 2.5px dashed #999;
            border-width: 2.5px;
            border-style: dashed;
            border-color: var(--color-medium);
            box-sizing: border-box;
            padding: 10px;

            >div {
                text-align: center;
                color: var(--color-text-tint);
                width: 100%;

                svg {
                    font-size: 30px;
                }

                >span {
                    display: block;
                    font-weight: 400;
                    font-size: .8em;
                }
            }

            >label {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                cursor: pointer;
            }
        }
    }

    .readonly-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        >img {
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
    }

    .cropped-img-wrapper {
        // width: 110px;
        // height: 110px;
        // position: relative;
        // border-radius: 10px;
        // overflow: hidden;
        background-color: #fff;
        border-radius: 10px;
        width: 200px;
        height: 200px;
        position: relative;
        /* Esto posicionará el ícono de eliminar correctamente */
        display: flex;
        justify-content: center;
        /* Centra la imagen horizontalmente */
        align-items: center;
        /* Centra la imagen verticalmente */
        overflow: hidden;

        /* Esconde cualquier parte de la imagen que exceda el contenedor */
        >img {
            // width: 100%;
            // display: block;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            /* Mantiene la relación de aspecto de la imagen */
            width: auto;
            /* Mantiene la relación de aspecto de la imagen */
        }

        >button {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: rgba($color: #fff, $alpha: .7);

            /* svg {
                color: var(--color-text-tint);
            } */
        }

    }

    .field-body {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }


}