.readonly-billing-field {
    .items-wrapper {
        margin-bottom: 20px;



        table {
            border-collapse: separate;
            border-spacing: 0px 10px;
            width: 100%;

            th {
                padding: 5px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .readonly-billing-field {
        .items-wrapper {
            table {
                tr {
                    td {
                        &:last-child {
                            padding: 10px 0;
                            justify-content: space-between;

                        }
                    }
                }
            }
        }

    }
}