.settings-expanded-card-container {
    position: relative;


    .expanded-card-body {
        //padding-top: 30px;

        .expandable-section-header {
            padding-left: 8px;

            h6 {
                font-size: 22px;
                font-weight: 700;

            }
        }
    }
}