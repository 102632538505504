.multi-checkbox-filter-container {
    .filter-label {
        font-weight: bold;
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: column;
    }

    .checkbox-item {
        display: flex;
        align-items: center;
        cursor: pointer;

        .checkbox-label {
            margin-left: 8px;
        }
    }
}