.preview-form-modal-body {
    padding: 10px 15px 30px 15px;
    display: flex;
    justify-content: center;

    >div {
        margin-top: 10px;
        width: 100%;
        max-width: 900px;

        >.paper {
            position: relative;
            min-height: 200px;

            >a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border-radius: 99px;
                background-color: #fff;
                position: absolute;
                right: -15px;
                top: -45px;
                color: var(--color-text);
                cursor: pointer;
                z-index: 1;

                >svg {
                    font-size: 20px;
                }

            }
        }
    }

}

@media screen and (max-width: 600px) {
    .form-modal-body {
        >div {
            >.paper {
                >a {
                    width: 40px;
                    height: 40px;
                    right: -10px;
                    top: -40px;
                }
            }
        }
    }
}