.website-builder-modal-container {
    .configuration-item-editor-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 85%;
        max-width: 250px;
        background-color: #fff;
        transition: transform .4s ease-in-out;
        z-index: 2;

        h2 {
            margin: 0 0 10px 0;
            padding: 0;
            font-weight: 800;
            font-size: 19px;
        }

        >a {
            display: flex;
            width: 40px;
            height: 40px;
            color: #fff;
            justify-content: center;
            align-items: center;
            border-radius: 10px 0px 0px 10px;
            cursor: pointer;
            position: absolute;
            left: -40px
        }

        >a:nth-of-type(1) {
            top: 40px;
            background-color: var(--color-dark);
        }




        >div {
            overflow-y: auto;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            box-sizing: border-box;

            >.tabs-wrapper {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin: 0;

                .segment-tabs-container {
                    height: auto;

                    a {
                        width: 28px !important;
                        margin: 4px;
                        padding: 2px;

                        >svg {
                            font-size: 20px;
                        }
                    }

                }
            }

            >.other-actions {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin: 0;
                flex-wrap: wrap;


            }

            >.main-actions {

                .action-wrapper {
                    padding: 6px 0;

                    >label {
                        display: block;
                        text-align: left;
                        margin-bottom: 3px;
                        font-weight: 500;
                        color: var(--color-text-tint);
                        font-size: .8em;
                        margin-left: 8px;
                    }

                    >.input-group {
                        >select {
                            padding: 5px;
                            border-radius: 5px;
                            border: 1px solid #ccc;
                            height: 30px;
                            box-sizing: border-box;
                            margin-left: 3px;
                        }
                    }

                }


                label {
                    >span {
                        position: relative;
                        top: -4px;
                        left: -4px;
                        margin-bottom: 3px;
                        font-weight: 500;
                        color: var(--color-text-tint);
                        font-size: 0.8em;
                    }
                }



            }

            // >div {
            //     margin: 20px 0;


            // }

        }
    }

    .configuration-item-editor-container.visible {
        transform: translateX(0);
    }

    .configuration-item-editor-container.hidden {
        transform: translateX(100%);
    }
}


@media screen and (max-width: 600px) {
    .configuration-item-editor-container {
        >a:nth-of-type(1) {
            top: 140px;
        }

        >a:nth-of-type(2) {
            top: 200px;
        }
    }
}