.workflow-entry-list-modal-container {
    .header-searchbar-wrapper {
        padding-right: 10px;
    }



    .list-container {
        box-sizing: border-box;
        padding: 10px;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }
}