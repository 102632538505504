.search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 99px;
    padding: 5px 5px 5px 10px;

    >svg {}

    >input {
        padding: 0px 5px 0px 10px;
    }

    >button {
        >svg {
            font-size: 16px;
        }
    }
}