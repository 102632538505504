.single-breakdown-indicator-card-body-container {
    // .current-item {
    //     margin-bottom: 5px;

    //     >span {
    //         font-weight: 800;
    //         font-size: .9em;
    //         display: block;
    //         text-align: center;
    //     }

    //     >div {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         box-sizing: border-box;
    //         font-size: .9em;

    //         >span {
    //             display: flex;
    //             flex-direction: column;
    //             align-items: center;
    //             justify-content: center;
    //             padding: 0 5px;

    //             >span {
    //                 font-weight: 300;
    //                 font-size: .9em;

    //             }

    //             >div {
    //                 font-weight: 800;
    //             }
    //         }


    //     }
    // }

    // .current-sub-item {
    //     position: relative;
    //     top: -4px;

    //     >span {
    //         display: block;
    //         text-align: center;
    //         font-size: .9em;
    //         font-weight: 400;

    //         text-align: center;
    //         display: -webkit-box;
    //         -webkit-box-orient: vertical;
    //         overflow: hidden;
    //         text-overflow: ellipsis;
    //         -webkit-line-clamp: 2;
    //         line-clamp: 2;
    //         height: 30px;
    //     }

    //     >div {}
    // }

    .current-data {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        >strong {
            font-size: 15px;
            font-weight: 900;
        }

        >span {
            display: block;
            margin-top: 2px;

            font-weight: 400;
            font-size: 13px;
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            height: 22px;

            >div {}
        }

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0px;
            margin-bottom: 2px;

            >span {
                display: block;
                margin-left: 3px;
                font-weight: 400;
                position: relative;
                //top: -1px;
                font-size: 12px;
            }
        }
    }

    .pie-chart-wrapper {
        display: flex;
        align-items: center;

        .pie-chart-current-sub-item {
            margin-left: 5px;
            text-align: left;
            width: 63px;

            >span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                height: 2.4em;
                //background-color: red;
                margin-top: 5px;

            }

            .animated-number-container {
                justify-content: flex-start;
            }
        }
    }

    .no-data {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
    }
}