.pie-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .graph {
        background-color: #fff;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        max-width: 500px;
        //background-color: red;
    }

    @keyframes animWidth {
        from {
            width: 0%;
        }
    }
}