.notifications-page-container {
    .page-body {
        padding: 10px;

        >.tabs-wrapper {
            display: flex;
            justify-content: center;
            //margin-top: 10px;

            .pill-tabs .MuiTabs-scroller>div button {
                background-color: #fff;
            }
        }

        .notifications-list {

            width: 100%;
            max-width: 700px;
            margin: 10px auto 20px auto;

            >div {
                position: relative;
                background-color: #fff;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                margin-bottom: 30px;
                height: 60px;
                border-radius: 100px 20px 20px 100px;
                padding-left: 80px;
                filter: drop-shadow(0 1.5px 0 #ddd);

                >div:nth-of-type(1) {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    background-color: #fff;
                    top: -5px;
                    left: 0;
                    border-radius: 100%;
                    //border: 7px solid white;
                    padding: 7px;

                    >img {
                        width: 100%;
                        border-radius: 99px;
                    }
                }

                >div:nth-of-type(2) {}

                >div:nth-of-type(3) {}
            }
        }

        .loading-btn-wrapper {
            display: flex;
            justify-content: center;
            padding: 0 5px;
        }
    }
}