.long-text-field-wrapper {
    width: 100%;
    border-color: var(--color-medium);
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    box-sizing: border-box;
    //background-color: #fff;
    //background: var(--color-light);
    position: relative;



    >label {
        position: absolute;
        top: -13px;
        left: 10px;
        display: inline-block;
        padding: 0 5px;
        background: #fff;
        font-weight: 600;
        color: var(--color-text);

    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;


        >div:nth-of-type(1) {
            width: 100%;

            span {
                //height: 40px;
                line-height: 34px;
                color: var(--color-text);
                display: block;
                font-weight: 400;
                padding-top: 10px;
                padding-bottom: 10px;
                box-sizing: border-box;
                line-height: 1.2;

            }

            textarea {
                width: 100%;
                font-size: 16px;
                border: none;
                background-image: none;
                font-family: inherit;
                background-color: #fff !important;
                display: block;
                font-weight: 400;

                padding: 10px;
                //height: 100px;
                line-height: 1.2;
                box-sizing: border-box;
                color: var(--color-text);
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;

                cursor: pointer;
                color: var(--color-text-tint);
            }
        }

        /* svg {
            color: var(--color-text-tint);
        } */
    }

}