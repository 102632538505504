.expanded-card-modal-container {
    .modal-window {
        width: 100%;
        height: 85%;
        max-width: 800px;
        padding: 10px;
        background-color: #fff;
        border-radius: 20px;
        position: relative;
        filter: drop-shadow(0px 2px 0 #bbb);

        >a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 99px;
            background-color: #fff;
            position: absolute;
            right: -20px;
            top: -20px;
            color: var(--color-text);
            cursor: pointer;

            >svg {
                font-size: 20px;
            }
        }

        >div {
            overflow: auto;
            height: 100%;
        }
    }

    .spinner-wrapper {
        margin-top: 200px;
    }

    .expanded-card-body {
        padding-top: 10px;

    }
}