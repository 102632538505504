.paper {
    width: 100%;
    max-width: 1200px;
    margin-top: 38px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    background: #fff;
    border-radius: 0 0 10px 10px;
    padding: 15px;
    position: relative;
    box-sizing: border-box;
    filter: drop-shadow(0 1.5px 0 #ccc);

    &::before {

        content: "";
        display: block;
        height: 30px;
        width: 30px;
        position: absolute;
        top: -30px;
        left: 0;
        background: url(../../public/assets/folded-edge.svg);
        z-index: 1;
    }

    >span:nth-of-type(1) {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 30px 30px;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        left: 0;
        top: -30px;
    }

    >span:nth-of-type(2) {
        height: 38px;
        width: calc(100% - 30px);
        position: absolute;
        left: 30px;
        top: -30px;
        background: #fff;
        border-radius: 0 10px 0 0;
    }
}