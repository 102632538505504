.editable-simple-payment-field-container {


    //display: flex;
    //justify-content: flex-end;
    //margin-top: 20px;

    >div {
        background-color: var(--color-light);
        padding: 10px;
        border-radius: 10px;

        >h3 {
            padding: 5px;
            margin: 0px;
            text-align: right;
            font-weight: 800;
            font-size: 1.2em;
        }

        >div.payment-methods-wrapper {
            >div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                margin: 5px 0px;

                >span {
                    min-width: 100px;
                }

                >div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;



                }
            }
        }

        >div.total-paid-wrapper {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;


            >span {
                font-weight: 800;
                font-size: 1.2em;
            }

            >strong {
                font-weight: 800;
                font-size: 1.4em;
            }
        }
    }

}