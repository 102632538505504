.new-workflow-entry-modal-container {
    .workflow-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;

        >button {
            width: 100%;
            max-width: 100px;
            padding: 10px 10px 25px 10px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;
            border-radius: 10px;
            text-align: center;
            font-weight: 700;
            filter: drop-shadow(0px 1.5px 0 #ccc);
            position: relative;
            cursor: pointer;
            overflow: inherit;
            color: var(--color-text);

            >div {
                position: absolute;
                left: 0px;
                right: 0px;
                bottom: -15px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                >span {
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    border-radius: 99px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >svg {
                        position: relative;
                        top: 5px;
                        color: var(--color-primary);
                    }
                }
            }

            &:disabled {
                // Estilos para el botón deshabilitado
                background-color: #ccc;
                color: #999;
                cursor: not-allowed;

                >div {
                    >span {
                        background-color: #ccc;

                        >svg {

                            color: #999;
                        }
                    }
                }
            }
        }
    }
}