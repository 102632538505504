.autocomplete-container {
    width: 100%;

    .MuiTextField-root {
        fieldset {
            border-radius: 10px;
            border-width: 2px;
            border-style: solid;
            border-color: var(--color-medium);
        }
    }

    .MuiAutocomplete-input {
        height: 28px;
        font-size: .9em;
    }
}

.autocomplete-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >img {
        border-radius: 99px;
        width: 40px;
    }

    >div {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        >div {
            line-height: 1;
            font-weight: 600;
        }

        >span {
            font-weight: 300;
            font-size: .9em;
        }
    }
}