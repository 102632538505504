.users-cell-container {
    // >a {

    //     position: relative;
    //     //display: block;
    //     width: 80px;
    //     height: 40px;

    //     display: flex;
    //     justify-content: flex-end;
    //     align-items: center;
    //     cursor: pointer;

    //     >img {
    //         border-radius: 99px;
    //         width: 35px;
    //         border-width: 3px;
    //         border-style: solid;
    //         border-color: var(--color-light);
    //         position: absolute;
    //         top: 0;
    //         //position: relative;
    //     }

    //     // >img:nth-of-type(1) {
    //     //     left: 0px;
    //     // }

    //     // >img:nth-of-type(1) {
    //     //     left: 20px;
    //     //     z-index: 1;
    //     // }

    //     >div {
    //         border-radius: 99px;
    //         background-color: var(--color-dark);
    //         width: 35px;
    //         height: 35px;
    //         color: #fff;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         font-size: .9em;

    //         position: absolute;
    //         left: 40px;
    //         //left: -20px;
    //         z-index: 2;
    //         border-width: 3px;
    //         border-style: solid;
    //         border-color: var(--color-light);

    //     }

    //     >span {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         color: #fff;
    //         background-color: var(--color-dark);
    //         width: 35px;
    //         height: 35px;
    //         border-radius: 99px;

    //     }
    // }
    >a {

        position: relative;
        //display: block;
        width: 80px;
        height: 40px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;


        >img {
            border-radius: 99px;
            width: 34px;
            border-width: 3px;
            border-style: solid;
            border-color: var(--color-light);

            position: relative;
        }

        >div {
            border-radius: 99px;
            background-color: var(--color-dark);
            width: 34px;
            height: 34px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9em;

            // position: absolute;
            // left: 40px;
            // //left: -20px;
            z-index: 2;
            border-width: 3px;
            border-style: solid;
            border-color: var(--color-light);
            position: relative;
        }

        >span {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: var(--color-dark);
            width: 34px;
            height: 34px;
            border-radius: 99px;
            margin-top: 3px;
        }

        >* {
            position: absolute !important;
            top: 0;
            right: 0;
            //right: -20px;
        }

        // >*:last-child {
        //     right: 0px
        // }

    }
}