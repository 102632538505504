.image-radio-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding-bottom: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;

            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }

        >div {

            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 35px 10px 10px 10px;
    }

    .editable-items {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        >a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 10px;
            margin: 10px;
            background-color: #fff;
            border-radius: 20px;
            width: 200px;
            position: relative;
            cursor: pointer;

            >img {
                border-radius: 10px;
            }

            >span {
                display: block;
                text-align: center;
                padding-bottom: 15px;
                position: relative;
                top: 2px;
            }

            >div {
                position: absolute;
                bottom: -10px;
                height: 35px;
                width: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;

                >svg {
                    font-size: 30px;
                }
            }
        }
    }

    .readonly-item {
        >div {
            display: flex;
            justify-content: center;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 10px;
                margin: 10px;
                background-color: #fff;
                border-radius: 20px;
                width: 200px;
                position: relative;
                cursor: pointer;

                >div {
                    >img {
                        border-radius: 10px;
                    }

                }

                >span {
                    display: block;
                    text-align: center;
                    //padding-bottom: 5px;
                    position: relative;
                    top: 0px;
                    margin-top: 5px;
                    font-weight: 600;
                }
            }
        }

    }
}