.size-field {

    >.checkbox {

        display: block;

        >div {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            >svg {
                font-size: 25px;
                color: var(--color-text);
            }
        }
    }
}