.card-preview-container {

    >svg {
        // width: 110px;
        // height: 110px;
    }

    .preview-values {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        >.preview-value-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 3px 0;

            >div {
                font-weight: 900;
                font-size: 24px;
            }

            >span {
                text-align: center;
                display: block;
                font-weight: 400;
            }
        }
    }

    .preview-values.lg {
        >.preview-value-item {
            >div {
                font-size: 24px;
            }

            >span {
                font-size: 15px;
            }
        }
    }

    .preview-values.md {
        >.preview-value-item {
            >div {
                font-size: 20px;
            }

            >span {
                font-size: 14px;
            }
        }
    }

    .preview-values.sm {
        >.preview-value-item {
            >div {
                font-size: 18px;
            }

            >span {
                font-size: 13px;
            }
        }
    }
}