.user-tabs-container {
    margin-bottom: 10px;

    .MuiTabs-scroller {
        background-color: transparent;
        padding: 10px;

        >div {
            justify-content: flex-start;
        }

        .MuiTabs-indicator {
            display: none;
        }

        a {
            padding: 5px !important;
            min-width: auto !important;
            padding: 0 !important;
            cursor: pointer;
            overflow: initial !important;
            filter: drop-shadow(0px 1.5px 0 #ccc);

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 10px 5px 5px;
                border-radius: 99px;

                >.icon-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    //background-color: red;
                    border-radius: 99px;


                    >svg {

                        height: 40px;
                    }
                }


                >img {
                    width: 40px;
                    border-radius: 99px;
                }

                >span {
                    line-height: 1;
                    margin-left: 5px;
                }

                >.badge {
                    font-weight: 700;
                    position: absolute;
                    top: -3px;
                    left: 30px;
                    width: 20px;
                    height: 20px;
                    background-color: var(--color-primary);
                    border-radius: 99px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-size: .9em;
                }
            }

            >div.all {
                background-color: #fff;

                >span {

                    margin-left: 0px;
                }
            }


        }

        a {
            .selected {
                background-color: var(--color-primary);
                color: #fff;


            }

            >.selected.all {
                background-color: var(--color-primary);
            }
        }

    }
}