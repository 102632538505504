.website-builder-modal-container {
    height: 100%;
    box-sizing: border-box;

    .modal-body {
        height: 100%;
        position: relative;
        box-sizing: border-box;
    }

    //position: relative;
    .container-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //background-color: blue;
        padding: 5px 5px 5px 10px;
        box-sizing: border-box;
        z-index: 1;

        >div:nth-of-type(1) {
            display: flex;
            align-items: center;
            position: relative;
            top: -3px;

            >h2 {
                padding: 0;
                margin: 0;
                font-weight: 900;
                font-size: 20px;
            }

            >button {
                margin-left: 5px;
                position: relative;
                top: 3px;
            }
        }

    }

    .container-body {
        height: 100%;
        position: relative;
        box-sizing: border-box;

        .pages-wrapper {
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            //background-color: blue;
            z-index: 2;
        }

        .main-content {

            height: 100%;
            position: relative;
            padding-top: 90px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            overflow: hidden;

            .configuration-item-selector-wrapper {
                width: 100%;
                max-width: 180px;
                //height: 100%;
                box-sizing: border-box;
                transition: transform 0.3s ease-in-out;
                position: absolute;
                top: 80px;
                bottom: 0px;
                left: 0px;
                //padding-top: 80px;
                z-index: 99;
                background-color: var(--color-light);

                >a {
                    display: none;
                    width: 40px;
                    height: 40px;
                    background-color: var(--color-primary);
                    color: #fff;

                    justify-content: center;
                    align-items: center;
                    border-radius: 0 10px 10px 0;
                    cursor: pointer;
                }

                >div {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                }
            }

            .device-preview-wrapper {
                position: absolute;
                top: 0px;
                left: 0px;
                padding-top: 70px;
                padding-left: 180px;
                padding-right: 250px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                //background-color: red;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
            }
        }
    }

    .secondary-fab:hover {
        background-color: var(--color-dark) !important;
    }
}

@media screen and (max-width: 600px) {
    .website-builder-modal-container {
        .main-content {
            .configuration-item-selector-wrapper {
                z-index: 99;
                width: 85%;
                max-width: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: var(--color-light);

                >a {
                    display: flex !important;
                    position: absolute;
                    top: 10px;
                    right: -40px;
                    z-index: 11;
                }
            }

            .configuration-item-selector-wrapper.visible {
                transform: translateX(0);
            }

            .configuration-item-selector-wrapper.hidden {
                transform: translateX(-100%);
            }

            .selected-configuration-items-wrapper {
                position: absolute;
                left: 0;
                right: 0;
            }

            .device-preview-wrapper {

                padding-left: 0px !important;
                padding-right: 0px !important;

            }
        }
    }
}