.filled-shape-chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    >.graph {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        >div {
            display: flex;
            flex-direction: column;
            align-items: center;

            >div:nth-of-type(1) {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin: 5px;

                >div:nth-of-type(1) {
                    position: relative;
                }

                >div:nth-of-type(2) {
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background: var(--color-medium);
                    z-index: -1;
                }

                >div:nth-of-type(3) {
                    position: absolute;
                    left: 1px;
                    bottom: 1px;
                    z-index: -1;
                    animation: animHeight 1s ease-in-out;
                }
            }

            >div:nth-of-type(2) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                top: -8px;

                >div {
                    font-weight: 900;
                    font-size: 1.2em;
                    line-height: 1;
                }

                >span {
                    font-weight: 300;
                    color: var(--color-text-shade);
                    font-size: .8em;
                    text-align: center;
                }
            }

            .label {}
        }
    }

}

@keyframes animHeight {
    from {
        height: 0px;
    }
}