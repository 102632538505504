.collapsed-header-container {

    position: relative;
    overflow: visible;

    >a {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 999;
    }

    >.drawer {
        padding-top: 40px;
        z-index: 99;
        width: 100%;
        position: absolute;
        top: -3px;
        //bottom: 0;
        left: -3px;
        transition: transform 0.3s ease-in-out;
        // display: flex;
        // flex-direction: column;
    }

    >.drawer.visible {
        transform: translateX(0);
    }

    >.drawer.hidden {
        transform: translateX(-100%);
    }
}