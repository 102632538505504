.comments-drawer-container {
    box-sizing: border-box;
    position: relative;

    .drawer-header {

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 50px;
        z-index: 1;
    }

    .drawer-body {
        //padding-bottom: 110px;
        //padding-top: 60px;
        position: absolute;
        bottom: 110px;
        left: 0;
        right: 0;
        top: 50px;
        background-color: var(--color-light);
        overflow-y: scroll;
    }

    .drawer-footer {
        width: 100%;
        //background-color: red;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 5px;
        box-sizing: border-box;
        background-color: var(--color-light);

        >textarea {
            width: 100%;
            height: 100px;
            border: 2px solid var(--color-medium);
            border-radius: 10px;
            margin-right: 3px;
            padding: 5px;
            box-sizing: border-box;
            color: var(--color-text);
        }
    }

}