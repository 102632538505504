.text-editor-field-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 5px solid var(--color-light);

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px 5px 20px;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            //z-index: 1;
            font-size: 1em;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 0 10px;
    }

    .field-label {
        font-weight: 500;
        padding: 10px;
        font-size: 1.1em;
        text-align: left;
        display: block;
    }

}

.text-editor-field-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    >button {
        color: var(--color-text);
        margin: 3px;
    }

}

.text-editor-field-item:first-child {
    margin-top: -20px;
}

.text-editor-field-item-content {
    border-radius: 10px;
    padding: 22px 10px 10px 10px;
    border: 2px solid var(--color-medium);

    textarea {
        height: 120px;
        border: 1px solid var(--color-medium);
        border-radius: 10px;
        width: 100%;
    }

    .image-item-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;

        .image-item-description {
            padding: 10px 0 0 0;

            >span {
                font-weight: 600;
                display: block;
                padding-bottom: 10px;
            }

            >textarea {
                border: 1px solid var(--color-medium);
                box-sizing: border-box;
                border-radius: 10px;
                font-weight: 300;
                color: var(--color-text);
                padding: 5px;

            }
        }
    }

    .list-item-wrapper {
        >div:nth-of-type(1) {


            >div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                >div {
                    padding-right: 4px;
                }

                textarea {
                    height: 60px;
                    // border: 1px solid var(--color-medium);
                    // border-radius: 10px;
                    // width: 100%;
                }

                button {
                    color: var(--color-text);
                }
            }

        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
        }

    }

    // >span {
    //     display: block;
    //     position: relative;
    //     padding: 10px;
    //     background-color: #fff;
    //     top: -30px;
    //     font-weight: 600;
    // }

    table {
        border-collapse: separate;
        border-spacing: 5px;
        display: table;
        margin: 0;
        padding: 0;
        width: 100%;

        td {
            border: 1px solid var(--color-medium);
        }

        td:last-child {
            border: none;
        }

        tr:first-child {
            td {
                border: none;
            }
        }

        .col-btns,
        .row-btns {
            display: flex;
            justify-content: center;

            >button {
                color: var(--color-text);
            }

        }

    }



    .preview-img-wrapper {
        position: relative;
        width: 100%;
        max-width: 200px;

        >img {
            width: 100%;
            display: block;
        }

        >button {
            position: absolute;
            top: 10px;
            right: 5px;
        }
    }
}

.text-editor-field-item-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    top: 22px;
    z-index: 1;

    >span {
        display: block;
        padding: 0 10px;
        background-color: #fff;
        font-weight: 600;
        margin-left: 20px;
    }

    >div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 30px;
        background-color: #fff;

        >button {
            color: var(--color-text);
        }
    }
}