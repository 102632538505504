.select-modal-container {
    .header-searchbar-container {
        margin-right: 10px;
    }

    .fab {
        position: fixed;
        bottom: 20px;
        right: 20px
    }

    .modal-body {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 10px;
        box-sizing: border-box;

        .empty-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 100px;

            >svg {
                font-size: 50px;
                color: var(--color-medium);
            }

            >p {
                font-weight: 500;
                color: var(--color-text);
            }
        }

        .spinner-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 100px;
        }

        >a {
            filter: drop-shadow(0px 1px 0 #ccc);
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 15px;
            padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;

            >div {
                >strong {
                    font-weight: 600;
                    display: block;
                }

                >span {
                    font-weight: 300;
                    font-size: .9em;
                }
            }
        }

    }
}