.device-preview-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 90%;
    width: 90%;

    >div {
        transition: transform 0.5s ease;
        position: relative;
        //margin: 20px;
        height: 100%;
        width: 100%;
        background-color: var(--color-dark);
        border-radius: 10px;
        padding: 10px;
        box-sizing: border-box;

        .screen {
            background-color: white;
            //overflow: hidden;
            //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow-y: auto;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 100%;
        }



        .power-button {
            position: absolute;
            bottom: 5px;
            //right: 10px;
            width: 16px;
            height: 16px;
            background-color: #fff;
            border-radius: 50%;
            left: 50%;
            margin-left: -8px;
        }

        .speaker-line {
            position: absolute;
            top: 7px;
            width: 50px;
            height: 5px;
            background-color: #fff;
            border-radius: 2px;
        }

        .pc-base {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            background-color: #ccc;

            >span {
                transform: translateX(-50%);
                background-color: var(--color-dark);
                left: 50%;
                position: absolute;
            }

            >span:nth-of-type(1) {
                width: 60px;
                height: 10px;
                top: 0;
            }

            >span:nth-of-type(2) {
                width: 120px;
                height: 10px;
                top: 10px;
                border-radius: 99px;
            }
        }
    }

}

.device-preview-container.desktop {
    width: 90%;

    >div {
        .power-button {
            bottom: -5px;
            z-index: 1;
            width: 12px;
            height: 12px;
        }
    }
}

.device-preview-container.mobile {
    width: 200px;

    >div {
        padding-top: 20px;
        padding-bottom: 26px;

    }
}

.device-preview-container.tablet {
    width: 100%;
    max-width: 500px;

    >div {

        padding-bottom: 26px;

    }
}

/* Animaciones para el cambio de modo */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.device-preview-container.desktop .screen,
.device-preview-container.tablet .screen,
.device-preview-container.mobile .screen {
    animation: fadeIn 0.5s ease;
}