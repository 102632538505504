:root {
    --color-primary: #e16945;
    --color-primary-shade: #b45033;
    --color-primary-tint: #f8dad1;
    --color-secondary: #000000;
    --color-additional: #FFCE00;
    --color-dark: #4d6572;
    --color-medium: #9cb0be;
    //--color-medium: #bbcad4;
    --color-light: #f2f2f4;
    --color-text: #4d6572;
    --color-text-shade: #364750;
    --color-text-tint: #638192;
    --color-danger: #e34860;
    --color-warning: #fdb555;
    --color-success: #00ba6c;
}