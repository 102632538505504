.tasks-card-container {
    >.card-title {
        height: 22px;
    }

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100px;

        >div {
            margin: 5px 0;
        }
    }
}