.mini-column-line-chart-container {
    position: relative;

    >canvas {
        //margin: 3px;
        //box-sizing: border-box;
    }

    //padding: 3px;
    // flex: 1;
}