.workflow-entries-card-container {
    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        margin-top: 0px;


    }


}