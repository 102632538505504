.data-table-card-container {
    // h3 {
    //     font-weight: 300;
    //     text-align: center;
    //     display: block;
    //     padding: 3px;
    //     margin: 0;
    //     font-size: .8em;
    // }

    >div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

}