.readonly-list-field-container {
    .list-field-items {
        margin-top: 20px;

        >div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            padding: 5px;
            background-color: #fff;
            border-radius: 10px;
        }
    }
}