.slider-field-container {
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;

    .editable-content {
        >b {
            display: block;
            //padding: 10px;
            text-align: center;
            font-size: 1.2em;
            color: var(--color-text-tint);
        }
    }
}