.expandable-section-container {
    width: 100%;

    >.expandable-section-header {
        >div {
            padding: 5px 0px;

            h6 {
                margin: 0;
                padding: 0;
                font-size: 15px;
                font-weight: 600;
                color: var(--color-text);

            }

            >span {}
        }


    }
}