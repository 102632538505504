.multi-checkbox-field-container {

    .editable-content,
    .readonly-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    .checkbox-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 7px;

        .checkbox-label {
            margin-left: 3px;
            font-size: .9em;
            font-weight: 400;
        }
    }

    a.checkbox-item {
        cursor: pointer;
    }
}