.dividers-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-primary);
    height: 36px;

    .MuiTabs-root {
        min-height: 44px;
    }

    .MuiTabs-scroller {

        //padding-right: 15px;
        // border-bottom-width: 3px;
        // border-bottom-style: solid;
        // border-bottom-color: var(--color-primary);
        height: 42px;

        .MuiTabs-indicator {
            display: none;
        }

        >div {
            padding-left: 15px;
            padding-right: 20px;

            button,
            a {
                filter: drop-shadow(2px 0px 0 #ddd);
                background: var(--color-medium);
                border-radius: 15px 15px 0 0;
                min-height: 40px;
                font-weight: 500;
                line-height: 1;
                color: #fff;
                text-shadow: 0px 0px 19px #555;
                position: relative;

                overflow: inherit !important;

                svg {
                    position: absolute;
                    top: 20px;
                    fill: var(--color-medium);
                }

                svg:nth-of-type(1) {
                    left: -20px;
                }

                svg:nth-of-type(2) {
                    right: -20px;
                }
            }



            .Mui-selected {
                background-color: var(--color-primary);

                svg {
                    fill: var(--color-primary);
                }
            }
        }

    }
}

.divider-groups {
    padding: 10px;

    >h2 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-weight: 700;
        font-size: .9em;
        margin: 5px 0px;
    }

    >div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        button {
            color: var(--color-text-tint);
            font-size: .8em;
            font-weight: 300;
            min-width: auto;
        }

        button.selected {
            color: var(--color-primary);
            font-weight: 500;
        }
    }
}