.checkbox-item-field-container {
    cursor: pointer;
    display: block;

    .item-field-body {
        .checkbox {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 2px;
            color: var(--color-primary);

            >svg {
                font-size: 20px;
            }


        }
    }
}