.address-data-container {
    // display: flex;
    // justify-content: center;
    // align-items: flex-start;
    // flex-direction: column;
    // width: 100%;
    margin-bottom: 10px;

    >div {
        margin-bottom: 4px;
        font-size: .9em;

        >b {
            display: inline-block;
            margin-right: 5px;
        }
    }
}