.pill-tabs-container {
    .pill-tabs {
        >.MuiBox-root {
            >div {
                min-height: 52px;
            }
        }

        .MuiTabPanel-root {
            padding: 0px;
        }

        .MuiTabs-scroller {
            .MuiTabs-indicator {
                display: none;
            }

            >div {

                button,
                a {
                    filter: drop-shadow(0px 1.5px 0px #ccc);
                    background: var(--color-light);
                    min-height: 28px;
                    height: 28px;
                    min-width: 60px;

                    line-height: 1;
                    margin: 0 5px;
                    border-radius: 99px;
                    font-size: .8em;
                    font-weight: 600;
                }

                .Mui-selected {
                    background-color: var(--color-primary) !important;
                    color: #fff;
                    position: relative;
                    overflow: inherit;
                    font-weight: 700;

                    &::before {
                        content: "";
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        //border-top: 10px solid #fff;
                        border-top-width: 10px;
                        border-top-style: solid;
                        border-top-color: var(--color-primary);
                        bottom: 0;
                        display: block;
                        height: 0;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, 93%);
                        width: 0;
                    }
                }
            }
        }

        .MuiTabs-scrollButtons {
            svg {
                position: relative;
                top: -10px;
            }
        }
    }
}