.column-chart-container {
    .chart {
        margin: 20px 0;

        >div:nth-of-type(1) {
            display: flex;
            justify-content: space-between;

            .y-axis {
                width: 70px;


                display: flex;
                justify-content: space-between;
                align-items: space-between;
                flex-direction: column;


                >span {
                    transform: rotate(-20deg);
                    font-size: .9em;

                    transform-origin: 0%;
                    padding-left: 3px;
                    font-weight: 300;
                    color: var(--color-text);
                    position: relative;

                    //font-size: ;
                }
            }

            .main-content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex: 1;
                flex-direction: row;
                box-sizing: border-box;
                padding-right: 40px;

                >a {
                    //width: 40px;
                    //width: 100%;
                    flex: 1;
                    background-color: var(--color-primary);
                    //margin: 0 .5%;
                    margin: 0 3px;
                    animation: animHeight .5s ease-in-out;
                    border-radius: 10px 10px 0 0;
                }
            }
        }

        >.x-axis {
            height: 80px;
            width: 100%;
            //background-color: red;
            padding-top: 5px;
            padding-left: 70px;
            padding-right: 40px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            >span {
                transform: rotate(-20deg);
                transform-origin: 100% 0%;
                font-weight: 600;
                color: var(--color-text);
                font-size: .9em;
                // position: relative;
                // left: 5px;
            }
        }
    }



    @keyframes animHeight {
        from {
            height: 0px;
        }
    }
}

@media(max-width: 600px) {
    .column-chart-container {
        .chart {
            >div:nth-of-type(1) {
                .main-content {
                    >a {
                        margin: 0 1px;
                    }
                }
            }
        }
    }
}

.column-popover {
    color: var(--color-text-shade);

    >* {
        display: block;
        padding: 3px;
        text-align: center;
    }

    >b {
        font-weight: 900;
        font-size: 1em;
    }

    >span {
        font-weight: 300;
        font-size: 1.2em;
    }
}