.configuration-item-selector-container {
    .items-wrapper {
        display: flex;
        justify-content: 'flex-start';
        align-items: 'flex-start';
        flex-wrap: wrap;

        .card-button-container {
            font-size: 1em;
            height: 100px;
        }
    }
}