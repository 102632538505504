.comment-item-container {
    display: flex;

    align-items: flex-end;
    margin: 0px 5px 10px 5px;
    //background-color: red;
    justify-content: flex-start;

    >img {
        width: 34px;
        height: 34px;
        border-radius: 99px;
        position: relative;
        top: -6px;
        filter: drop-shadow(0 1.5px 0 #ccc);
    }

    >div {
        padding: 7px 10px;
        border-radius: 10px;
        background-color: #fff;
        font-size: 1em;
        font-weight: 300;
        background-color: #fff;
        position: relative;
        filter: drop-shadow(0 1.5px 0 #ccc);

        &::before {
            content: "";
            position: absolute;
            bottom: 12px;
            width: 0;
            height: 0;

        }

        >strong {
            display: block;
            margin-bottom: 2px;
            font-size: .8em;
        }

        >span {
            display: block;
            margin-top: 2px;
            font-weight: 500;
            font-size: .8em;
            color: var(--color-text-tint);
        }
    }
}

.comment-item-container.my-comment {
    flex-direction: row-reverse;

    >div {
        text-align: right;
        margin-right: 14px;

        &::before {
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #fff;
            right: -10px;
        }
    }
}

.comment-item-container.other-comment {
    >div {
        margin-left: 14px;

        &::before {
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #fff;
            left: -10px;
        }
    }


}