.MuiPickersPopper-paper,
.MuiPopover-paper {
    box-shadow: 0 1px 3px #ccc !important;
}

.popover-items {

    padding: 5px;

    >a {
        padding: 10px;
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    >a:not(:last-child) {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--color-light);
    }
}