.import-config-dialog-container {

    .import-config-dialog-title {
        font-weight: 900;
        text-align: center;
        //margin-top: 10px;
        padding: 5px;
        font-size: 1.1em;
    }

    .import-config-dialog-subtitle {
        font-weight: 300;
        text-align: center;
        //margin-top: 10px;
        padding: 0 5px;
        font-size: 1em;
        position: relative;
        top: -5px;
    }

    .import-config-dialog-description {
        font-weight: 300;
        padding-bottom: 20px;
        text-align: center;

    }

    .MuiDialogContent-root {
        padding: 10px;
    }

    .tabs-wrapper {
        display: flex;
        justify-content: center;
    }

    .upload-input {
        display: none;
    }

    .upload-button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .upload-button {
            //margin-left: 10px;

            strong {
                margin-right: 4px;
                position: relative;
                //top: 2px;
            }
        }

    }
}