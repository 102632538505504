.sign-in-page-container {
    width: 100%;
    background-color: #dce4e6;
    height: 100%;
    position: absolute;



    >.form-wrapper {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        position: relative;



        >h1 {
            margin: 0px;
            padding: 5px;
            text-align: center;
            font-weight: 600;
            font-size: 1.2em;
        }

        >form {
            position: relative;
            z-index: 99;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

        }

        >form>div {
            width: 100%;
            padding: 5px;
            box-sizing: border-box;
        }

        >form>div>button {
            width: 100%;

        }
    }

    >.spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 200px;
    }

    .MuiButton-root {
        border-radius: 99px;

    }

    .input-field-wrapper,
    .password-field-container {
        background: #fff;

    }

    .input-field-container {
        border-radius: 99px !important;
    }

}