.indicator-group-card-container {
    .btns-wrapper {
        padding: 0px 5px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -5px;

        >a {
            margin: 3px;

            >svg {
                font-size: 44px;
                cursor: pointer;
            }
        }
    }

    .current-data {
        margin-top: -15px;

        .title {
            text-align: center;
            font-weight: 400;
            font-size: .8em;
            margin-top: 6px;
            line-height: 1;
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            height: 28px;
        }

        >span {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }

        .animated-number-wrapper {
            font-weight: 900;
            font-size: 1.1em;
        }

        .measuremente-unit {
            text-align: center;
            font-size: .9em;
            font-weight: 800;
            // position: relative;
            // top: -3px;
        }

        .period {
            text-align: center;
            display: block;
            font-size: .9em;
            position: relative;
            top: -3px;
            font-weight: 700;
        }
    }
}