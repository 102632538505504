.card-button-container {
    background-color: var(--color-light);
    padding: 10px;
    border-radius: 10px;
    width: 90px;
    height: 80px;
    margin: 10px 10px 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: var(--color-text);
    text-align: center;
    flex-direction: column;
    filter: drop-shadow(0 2px 0 #ccc);

    >span {
        font-weight: 300;
        font-size: .9em;
        position: relative;
        line-height: 1;
    }

    >svg {
        margin-bottom: 5px;
        font-size: 40px;
    }

    >strong {
        font-weight: 500;
        font-size: .8em;
        position: relative;
        top: -5px;
    }

    >div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        bottom: -14px;
        right: 50%;
        margin-right: -14px;

        >svg:nth-of-type(1) {
            position: absolute;
            top: -1px;
            left: -1px;
            font-size: 28px;

        }

        >svg:nth-of-type(2) {
            position: absolute;
            top: 1px;
            left: 1px;
            font-size: 24px;
        }
    }
}


.card-button-container.lg {
    padding: 15px;
    border-radius: 20px;
    width: 100px;
    height: 80px;
    margin: 20px;

    >span {
        font-size: 1.1em;
    }

    >strong {
        font-size: 1.1em;
        top: -5px;
    }

    >div {
        width: 40px;
        height: 40px;
        bottom: -20px;
        margin-right: -20px;

        >svg:nth-of-type(1) {

            font-size: 40px;
        }

        >svg:nth-of-type(2) {

            top: -1px;
            left: -1px;
            font-size: 40px;


        }
    }
}

.card-button-container.sm {
    padding: 15px;

    width: 70px;
    height: 60px;
    margin-bottom: 20px;

    >svg {
        margin-bottom: 5px;
        font-size: 38px;
    }

    >span {
        font-size: .7em;
    }

    >strong {
        font-size: .7em;
        top: -5px;
    }

    >div {
        width: 28px;
        height: 28px;
        bottom: -14px;
        margin-right: -14px;

        >svg:nth-of-type(1) {

            top: -1px;
            left: -1px;
            font-size: 28px;

        }

        >svg:nth-of-type(2) {

            top: 1px;
            left: 1px;
            font-size: 24px;
        }
    }
}