.configuration-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    //padding: 10px;
    margin: 0 !important;
    padding-top: 0px;
    filter: none;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 15px;

        >label {
            display: inline-block;
            font-weight: 500;
            color: var(--color-text);
            font-size: 1em;
            padding-top: 10px;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 0 10px 5px 10px;

        >.editable-content {
            //padding: 0 10px;
            //margin-bottom: 20px;

            .btn-wrapper {
                svg {
                    margin-right: 5px;
                }

            }
        }

        >.readonly-content {
            >div {
                //padding: 0 10px;

                >div {
                    padding: 3px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    >strong {
                        margin-right: 5px;
                        font-weight: 500;
                        font-size: .9em;
                    }

                    >span {
                        font-weight: 300;
                    }
                }
            }

            >span {
                text-align: center;
                padding-bottom: 10px;
                display: block;
            }
        }

        .items-count {
            font-size: .9em;
            padding: 10px 10px 5px 8px;
        }
    }
}