.dynamic-form-container {
    position: relative;

    >form {
        position: relative;
        //padding-top: 70px;

        >h2 {
            position: absolute;
            right: 30px;
            top: -50px;
        }

        .MuiGrid-container {
            >div {
                margin-bottom: 15px;
            }
        }

        >.visible-id {
            color: var(--color-text-tint);
            font-size: .9em;
            display: block;
            text-align: left;
        }
    }

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    }

    .separator-field-container {
        display: block;
        font-weight: 700;
        padding: 10px;
        background-color: var(--color-text-tint);
        color: #fff;
        border-radius: 10px;
    }
}