.editable-list-field-container {
    .add-item-btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }

    .list-field-items {
        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            >div {
                flex: 1;
            }

            >button {
                margin-left: 5px;
            }
        }
    }
}