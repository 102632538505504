.tasks-map-modal-container {
    .header-container {
        position: relative;
        z-index: 1;
    }

    .modal-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

    }
}