.list-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    padding-bottom: 0px;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 5px 5px 5px 15px;
        box-sizing: border-box;

        >label {
            display: inline-block;
            //padding: 0 5px;
            //background-color: #fff;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            font-weight: 500;
            color: var(--color-text);
            z-index: 1;
            font-size: 1em;
        }

        >div {
            // position: absolute;
            // right: 4px;
            // top: 4px;
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding: 30px 10px 10px 10px;
    }
}