.mini-pie-chart-container {
    .graph {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;

        >a {
            box-sizing: border-box;
            height: 100%;
            background-color: var(--color-text);
            margin-left: .5%;
            animation: animWidth .5s ease-in-out;
            cursor: pointer;

            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:last-child {
                border-radius: 0 5px 5px 0;
            }
        }
    }

    @keyframes animWidth {
        from {
            width: 0%;
        }
    }
}