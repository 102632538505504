.grid-field-container {
    position: relative;
    background-color: var(--color-light);
    border-radius: 10px;
    margin: 0 !important;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    filter: none;

    .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 15px;

        >label {
            display: inline-block;
            font-weight: 500;
            color: var(--color-text);
            font-size: 1em;
        }

        >div {
            color: var(--color-text-tint);
            display: flex;
            justify-self: flex-end;
            align-items: center;

            a {

                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .field-body {
        padding-bottom: 5px;

        .editable-content {
            .add-btn-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5px;
            }

            .table-wrapper {
                padding: 0 20px;

                table {
                    border-collapse: separate;
                    border-spacing: 0 10px;
                    display: table;
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    thead {
                        >tr {
                            th {
                                font-weight: 600;
                                font-size: .9em;
                                text-align: center;
                                //text-align: left;
                                padding: 0 0 0 10px;
                                text-transform: uppercase;

                                &:last-child {
                                    width: 30px;
                                }

                                // a {
                                //     cursor: pointer;
                                //     display: flex;
                                //     justify-content: flex-start;
                                //     align-items: center;
                                //     line-height: 1;

                                //     svg {
                                //         font-size: .8em;
                                //         margin-right: 5px;
                                //     }
                                // }
                            }
                        }
                    }

                    tbody {
                        >tr {
                            //filter: drop-shadow(0px 1px 0 #ccc);
                            filter: none;
                            //cursor: pointer;
                        }
                    }

                    tr {
                        background: transparent;
                        border-radius: 10px;
                        padding-bottom: 10px;
                        width: 100%;

                        >td {
                            background: white;
                            padding: 10px;
                            //vertical-align: top;

                            >span {
                                padding: 5px;
                                display: block;
                                border-right: 1px solid #e8e8e8;

                                ion-thumbnail {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                >span.cell-money {
                                    display: flex;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    font-weight: 700;

                                    >.bib-currency {
                                        margin-right: 5px;
                                        display: block;
                                    }

                                }
                            }

                            // .checkbox-field {
                            //     
                            // }
                            .checkbox-wrapper {
                                display: block;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                cursor: pointer;

                                svg {
                                    font-size: 30px;
                                }
                            }

                            .icon-picker-btn-wrapper {
                                >a {
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;

                                    >span {
                                        text-align: center;
                                    }
                                }
                            }

                            .selected-icon {
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg {
                                    font-size: 50px;
                                }
                            }

                            &:first-child {
                                border-radius: 5px 0px 0px 5px;
                            }

                            &:last-child {
                                border-radius: 0px 5px 5px 0px;
                                vertical-align: middle;

                                >span {
                                    border: none;
                                }
                            }

                            &:nth-last-child(2) {
                                >span {
                                    border: none;
                                }
                            }
                        }
                    }
                }

                // th {
                //     position: -webkit-sticky;
                //     position: sticky;
                //     top: 63px;
                //     z-index: 2;
                //     background-color: var(--color-light);
                //     padding-bottom: 5px !important;
                //     // height: 50px;
                // }
            }

            .selected-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                >span {
                    margin-right: 5px;
                    font-weight: 500;
                }

                >button {
                    padding: 10px;

                    svg {
                        font-size: 15px;
                    }
                }


            }

            .number-input {
                border-radius: 10px;
                border-width: 2px;
                border-style: solid;
                border-color: var(--color-medium);
                height: 44px;
                width: 100%;
                box-sizing: border-box;
            }

            .autocomplete-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;

                >div {
                    flex: 1;

                }

                >button {
                    margin-left: 5px;
                }
            }

            .text-input,
            .long-text-input {
                border: 2px solid #bbcad4;
                border-radius: 10px;
            }

            .list-wrapper,
            .object-wrapper {
                .add-item-btn-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        font-size: .8em;

                        svg {
                            font-size: 1.1em;
                        }
                    }

                }

                .items-wrapper {
                    >div {
                        margin-bottom: 10px;
                        border: 2px solid #bbcad4;
                        border-radius: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-right: 5px;

                        svg {
                            font-size: 1.1em;
                        }
                    }
                }
            }

            .object-wrapper {


                .items-wrapper {
                    >div {
                        >span {
                            position: relative;
                            top: -2px;
                        }
                    }

                }
            }

            .images-field-container {
                padding: 0px;
                background-color: #fff;
            }

            .img-preview-wrapper {
                background-color: var(--color-light);
            }

            .radio-field-container {
                background-color: #fff;
            }
        }

        .readonly-content {
            >span {
                display: block;
                padding: 0px 0px 5px 0px;
                text-align: center;
            }
        }
    }

    .radio-field-container {
        padding-top: 0px;

        >div {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

.grid-field-container.mobile-view {
    .table-wrapper {
        padding: 0px !important;

        table {
            thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            tr {
                background: white !important;
                //box-shadow: 0 .5px 0 0 #ccc;
                display: block;
                margin-bottom: 15px;
                padding: 10px;
                position: relative;
                box-sizing: border-box;
                margin-bottom: 25px;

                td {
                    align-items: center;
                    background-color: transparent !important;
                    border-bottom: none;
                    display: flex;
                    justify-content: space-between;
                    text-align: right;

                    &:before {
                        content: attr(data-column-header);
                        font-weight: 600;
                        text-transform: uppercase;
                        text-align: start;
                        padding-right: 10px;
                        font-size: .9em;
                    }

                    span {
                        border: none !important;
                        margin-bottom: 0 !important;
                        padding: 0 !important;
                        text-align: right;
                    }

                    &:last-child {
                        //position: relative;
                        display: flex;
                        justify-content: center;
                        height: 5px;

                        >button {
                            position: relative;
                            bottom: -15px;
                            background-color: #fff;
                            margin: 0 3px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .grid-field-container {
        .table-wrapper {
            padding: 0px !important;

            table {
                thead {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                tr {
                    background: white !important;
                    //box-shadow: 0 .5px 0 0 #ccc;
                    display: block;
                    margin-bottom: 15px;
                    padding: 10px;
                    position: relative;
                    box-sizing: border-box;
                    margin-bottom: 25px;

                    td {
                        align-items: center;
                        background-color: transparent !important;
                        border-bottom: none;
                        display: flex;
                        justify-content: space-between;
                        text-align: right;

                        &:before {
                            content: attr(data-column-header);
                            font-weight: 600;
                            text-transform: uppercase;
                            text-align: start;
                            padding-right: 10px;
                            font-size: .9em;
                        }

                        span {
                            border: none !important;
                            margin-bottom: 0 !important;
                            padding: 0 !important;
                            text-align: right;
                        }

                        &:last-child {
                            //position: relative;
                            display: flex;
                            justify-content: center;
                            height: 5px;

                            >button {
                                position: relative;
                                bottom: -15px;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

}