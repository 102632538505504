.record-linking-modal-container {
    .autocomplete-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        >div {
            width: 100%;
            max-width: 700px;
            padding: 5px;
            background: #fff;
            border-radius: 99px;
            filter: drop-shadow(0px 2px 0 #ccc);
        }

        .autocomplete-container .MuiTextField-root fieldset {
            border-radius: 99px;
        }
    }

    .list-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        padding: 10px;

        >div {
            width: 100%;
            max-width: 700px;

            >div {
                position: relative;
                background-color: #fff;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                margin-bottom: 15px;
                height: 60px;
                border-radius: 20px 20px 20px 20px;

                padding-left: 20px;
                filter: drop-shadow(0px 2px 0 #ccc);

                >div.thumbnail-wrapper {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    background-color: #fff;
                    top: -5px;
                    left: -10px;
                    border-radius: 100%;
                    //border: 7px solid white;
                    padding: 7px;

                    >img {
                        width: 100%;
                        border-radius: 99px;
                    }
                }

                >div.main-content-wrapper {
                    color: var(--color-text);
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;

                    >strong {
                        line-height: 1;
                        display: inline-block;
                        margin-right: 4px;
                    }

                    >span {
                        font-weight: 300;
                        font-size: .9em;
                        line-height: 1;
                    }

                }

                >div.btn-remove-wrapper {}
            }
        }
    }
}