.date-field-container {
    width: 100%;
    border-color: var(--color-medium);
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    box-sizing: border-box;
    //background-color: #fff;
    //background: var(--color-light);
    position: relative;

    >label {
        position: absolute;
        top: -13px;
        left: 10px;
        display: inline-block;
        padding: 0 5px;
        background: #fff;
        font-weight: 600;
        color: var(--color-text);

    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;


        >div:nth-of-type(1) {
            width: 100%;

            span {
                height: 40px;
                line-height: 34px;
                color: var(--color-text);
                display: block;
                font-weight: 400;
                padding-top: 4px;
                box-sizing: border-box;
            }

            input {

                border-radius: 10px;
                width: 100%;
                height: 30px;
                line-height: 25px;
                border: none;
                font-size: .9em;
                padding: 5px;
                color: var(--color-text);
                font-weight: 500;
                outline: none;
                //background: var(--color-light);
                //background-color: #fff;
                //background-color: #fff !important;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                box-shadow: 0 0 0 30px #fff inset !important;
            }
        }

        >div:nth-of-type(2) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            color: var(--color-text-tint);
        }
    }

    .datepicker-wrapper {
        >div {
            width: 100%;

            >div {
                >fieldset {
                    border: none;
                }
            }
        }
    }
}