.password-field-container {
    width: 100%;
    border-color: var(--color-medium);
    border-width: 2px;
    border-radius: 99px;
    border-style: solid;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    position: relative;

    >label {
        position: absolute;
        top: -13px;
        left: 10px;
        display: inline-block;
        padding: 0 5px;
        background: #fff;
        font-weight: 600;
        color: var(--color-text);

    }

    >div:nth-of-type(1) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            border-radius: 99px;
            width: 100%;
            height: 30px;
            line-height: 25px;
            border: none;
            font-size: .9em;
            padding: 5px;
            color: var(--color-text);
            font-weight: 500;
            outline: none;
            //background-color: var(--color-light);

        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0 30px #fff inset !important;
        }


        button {
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            color: var(--color-text-tint);
        }
    }

}