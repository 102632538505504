.editable-checklist-field {
    .add-item-btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .list-field-items {
        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            >button:nth-of-type(1) {
                >svg {
                    font-size: 30px;
                }
            }

            >div {
                margin-right: 10px;
            }

        }

        >div.done {
            input {
                text-decoration: line-through;
            }
        }
    }
}