.search-place-container {
    position: relative;


    .places-autocomplete-wrapper {
        // position: absolute;
        // top: 10px;
        // left: 50%;
        // transform: translateX(-50%);
        // z-index: 10;
        // width: 300px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        padding-bottom: 10px;

        >div {

            width: 300px;
            box-sizing: border-box;
            border-color: var(--color-medium);
            border-width: 2px;
            border-radius: 10px;
            border-style: solid;
            padding: 5px;


            >input {
                width: 100%;
                box-sizing: border-box;
                border: none;
                background-color: transparent;
                outline: none;
                font-weight: 500;
                font-size: .9em;
                color: var(--color-text-tint);
                height: 25px !important;
                line-height: 20px !important;
            }


        }
    }

    .map-container {
        width: 100%;
        height: 70vh;
    }

    .combobox-input {
        width: 100%;
        padding: 0.5rem;
    }

    .info-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            max-width: 600px;

            >svg {
                margin: 5px;
                font-size: 20px;
            }

            >span {
                font-weight: 300;
            }
        }
    }
}