.editable-payment-field {
    .select-party-btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        >button {
            border-radius: 99px;
            border-width: 2px;
        }
    }

    .selected-party {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        >div {
            >strong {
                display: block;
                margin-bottom: 5px;

            }

            >span {
                font-weight: 300;
                font-size: .9em;
            }
        }

    }

    .payment-methods-wrapper {
        padding: 10px;
        border-radius: 10px;
        background-color: var(--color-light);

        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            margin: 5px 0px;

            >span {
                min-width: 100px;
            }

            >div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;



            }
        }
    }



    .total-paid-wrapper,
    .total-due-wrapper {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;


        >span {
            font-weight: 800;
            font-size: 1.2em;
        }

        >strong {
            font-weight: 800;
            font-size: 1.4em;
        }
    }

    .total-due-wrapper {
        >span {
            font-weight: 800;
            font-size: 1.1em;
        }

        >strong {
            font-weight: 800;
            font-size: 1.2em;
        }
    }
}