.active-filter-item-container {
    background-color: #fff;
    border-radius: 99px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: 10px;
    // margin-bottom: 10px;
    margin: 5px;
    filter: drop-shadow(0px 1.5px 0 #ccc);

    >span {
        line-height: 1;
        display: block;
    }

    >svg {
        color: var(--color-text-tint);
        font-size: 15px;
        margin-right: 5px;
    }

    >button {
        padding: 0px;
        margin-left: 5px;

        >svg {
            font-size: 20px;
        }
    }

}